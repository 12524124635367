import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'

// markup
const Imprevisibles = () => {
  const metaTitle = 'Les Imprévisibles | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'imprévisibles, spectacles, festif, surprise, musique'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Spectacles Imprévisibles Loto-Québec"
        htmlContent={
         <div>
          <StaticImage className="max-w-[300px]" src="../../images/rdv-loto-quebec.png" alt="Logo Loto-Québec"/>
        </div>
        }
      />
      <div className="container content pb-6">

        {/* YOUTUBE */}
        <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
          <div className="mt-10 relative h-[35rem]">
            <iframe
              title="Les Spectacles Imprévisibles Loto-Québec - recap"
              className="absolute top-0 left-0 w-full h-full mb-3rem"
              src={'https://www.youtube.com/embed/nMfhJVplBdg'}
              allowFullScreen=""
              referrerPolicy="origin-when-cross-origin"
              height={'100%'}
            ></iframe>
          </div>
        </div>

        {/* HIGHLIGHTED TEXT */}
        <div className='my-12 text-center'>
          <h5>Les surprises au Festif!, on connaît ça! Nos mythiques Spectacles Imprévisibles Loto-Québec sont la cerise sur le sundae de ta journée. Reste à l’affût de leurs annonces via l’application du Festif! en activant les notifications et sois parmi les quelques-un·es qui ont la chance d’y assister. Pour les victimes de FOMO, les Spectacles Imprévisibles Loto-Québec, c’est le rush ultime.</h5>
        </div>

        {/* DOWNLOAD BUTTONS */}
        <div className="my-10 text-center">
          <div className="w-full flex max-w-[90%] md:max-w-[60%] lg:max-w-[40%] px-auto mx-auto">
            <a href="https://apps.apple.com/ca/app/le-festif/id895648726" className="flex md:w-1/2 w-full" target="_blank" rel="noreferrer">
              <StaticImage className={'mr-2'}
                            src={'../../images/stores/appstore-logo.png'}
                            placeholder="blurred"
                            objectFit="contain"
                            alt="Logo App Store"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=ca.lefestif&hl=fr_CA&gl=US" className="flex md:w-1/2 w-full" target="_blank" rel="noreferrer">
              <StaticImage className={'ml-2'}
                            src={'../../images/stores/googleplay-logo.png'}
                            placeholder="blurred"
                            objectFit="contain"
                            alt="Logo Google Play Store" />
            </a>
          </div>
        </div>

        {/* LQ BANNER */}
        <div className="w-full center py-8">
          <div className="max-w-[728px] max-h-[90px] mx-auto">
            <a className="" href="https://societe.lotoquebec.com/fr/responsabilite-societale/commandites" rel="noreferrer noopener">
              <StaticImage src="../../images/partenaires/lotoquebec-banner.jpg"/>
            </a>
          </div>
        </div>

      </div>
    </Wolflayout>
  )
}

export default Imprevisibles
